import React, { useState, useEffect } from 'react';
import * as language from '../constants/languages';
import { getBranches } from '../services/services';

export default function StoreChoose() {
    const [currentLongitude, setcurrentLongitude] = useState('unknown')//Initial Longitude
    const [currentLatitude, setcurrentLatitude] = useState('unknown')//Initial Latitude
    const [branches, setbranches] = useState(null)
    const [isLoading, setisLoading] = useState(true)
    const [selectedBranch, setselectedBranch] = useState(null)

    // get the branch data
    async function getBranchesFunction() {

        const initial = localStorage.getItem('initial');
        await getBranches(language.companyidValue).then((branches) => {

            if (branches.status === 'success') {
                const event = new CustomEvent('storeSelected', { detail: branches.data[0].branchName });;
                localStorage.setItem('storeId', JSON.stringify(branches.data[0].branchId));
                localStorage.setItem('storeName', branches.data[0].branchName);
                window.dispatchEvent(event)
                localStorage.setItem('initial', '1');
            } 
            /* if (branches.status === 'success') {
                if (branches.data.length > 1) {
                    if (!initial) {
                        const event = new CustomEvent('storeSelected', { detail: branches.data[0].branchName });;

                        localStorage.setItem('storeId', JSON.stringify(branches.data[0].branchId));
                        localStorage.setItem('storeName', branches.data[0].branchName);
                        window.dispatchEvent(event)
                        localStorage.setItem('initial', '1');
                    } else {
                        setbranches(branches.data)
                        setisLoading(false)

                    }
                } else {
                    
                        setbranches (branches.data)
                        setisLoading (false)
                    
                }

            } */

        })
            .catch((error) => {
                // console.log(error, 'branch error');
            })
    }
    useEffect(() => {
        getBranchesFunction()
    }, [])
}